export const CS_FAQ_URL = 'tmap://customer-center?pageid=faq&extra=%7b%22categoryId%22%3a%2220703549107355%22%7d'
export const CARPROFILE_SCHEME = 'tmap://carprofile'
export type MenuSetItemName = 'CARSALE'
  |'MYCAR'
  | 'MYCAR_ENGINEOIL'
  | 'MYCAR_TIRE'
  | 'MYCAR_AIRCON'
  | 'MYCAR_AIR_FILTER'
  | 'MYCAR_RECALL'
  | 'MYCAR_WARRANTY'
  | 'EMERGENCY_CALL'
export interface MenuSetType {
  id: MenuSetItemName
  order: number
  img: string
  title: string
  action_id: string
  link: string
  startDateTime: string
  isRedDot: boolean
}
export const menuSetItems: MenuSetType[] = [
  {
    id: 'CARSALE',
    order: 9, // 내림차순으로 정렬하기 위해 역순으로 넘버링 적용
    img: 'img_carcare_item_buymycar',
    title: '내차사기',
    action_id: 'tap.menu_cardealer',
    link: 'tmap://webview?service=carsale&id=1&params=redirectUri%3DLw==',
    startDateTime: '20301231000000', // 내차사기는 레드닷과 상관없이 맨 처음 노출 적용
    isRedDot: false,
  },
  {
    id: 'MYCAR',
    order: 8, // 내림차순으로 정렬하기 위해 역순으로 넘버링 적용
    img: 'img_carcare_item',
    title: '내차관리',
    action_id: 'tap.menu_mycar',
    link: 'tmap://life?pageid=vehicle',
    startDateTime: '20301231000000', // 내차관리는 레드닷과 상관없이 맨 처음 노출 적용
    isRedDot: false,
  },
  {
    id: 'MYCAR_TIRE',
    order: 7,
    img: 'img_carcare_item_tire',
    title: '타이어',
    action_id: 'tap.menu_tire',
    link: 'tmap://life?pageid=vehicle_detail&extra=%7B%22seq%22%3A%22TIRE%22%7D',
    startDateTime: '',
    isRedDot: false,
  },
  {
    id: 'MYCAR_ENGINEOIL',
    order: 6,
    img: 'img_carcare_item_oil',
    title: '엔진오일',
    action_id: 'tap.menu_engineOil',
    link: 'tmap://life?pageid=vehicle_detail&extra=%7B%22seq%22%3A%22ENGINE_OIL%22%7D',
    startDateTime: '',
    isRedDot: false,
  },
  {
    id: 'MYCAR_AIRCON',
    order: 5,
    img: 'img_carcare_item_aircon_filter',
    title: '에어컨필터',
    action_id: 'tap.menu_airconFilter',
    link: 'tmap://life?pageid=vehicle_detail&extra=%7B%22seq%22%3A%22AIRCON_FILTER%22%7D',
    startDateTime: '',
    isRedDot: false,
  },
  {
    id: 'MYCAR_AIR_FILTER',
    order: 4,
    img: 'img_carcare_item_air_filter',
    title: '에어필터',
    action_id: 'tap.menu_airFilter',
    link: 'tmap://life?pageid=vehicle_detail&extra=%7B%22seq%22%3A%22AIR_FILTER%22%7D',
    startDateTime: '',
    isRedDot: false,
  },
  {
    id: 'MYCAR_RECALL',
    order: 3,
    img: 'img_carcare_item_recall',
    title: '리콜',
    action_id: 'tap.menu_recall',
    link: 'tmap://life?pageid=vehicle_detail&extra=%7B%22seq%22%3A%22RECALL%22%7D',
    startDateTime: '',
    isRedDot: false,
  },
  {
    id: 'MYCAR_WARRANTY',
    order: 2,
    img: 'img_carcare_item_warranty',
    title: '보증기간',
    action_id: 'tap.menu_warranty',
    link: 'tmap://life?pageid=vehicle_detail&extra=%7B%22seq%22%3A%22WARRANTY%22%7D',
    startDateTime: '',
    isRedDot: false,
  },
  {
    id: 'EMERGENCY_CALL',
    order: 1,
    img: 'img_carcare_item_siren',
    title: '긴급출동',
    action_id: 'tap.menu_insurcall',
    link: 'tmap://life?pageid=etc_emergency_call',
    startDateTime: '',
    isRedDot: false,
  },
]

export interface MonthlyNightTime {
  month: number
  nightTime: string
}
export const monthlyNightTimeTable: MonthlyNightTime[] = [
  {
    month: 1,
    nightTime: '저녁 7시 ~ 새벽 05시',
  },
  {
    month: 2,
    nightTime: '저녁 7시 30분 ~ 새벽 05시',
  },
  {
    month: 3,
    nightTime: '저녁 8시 ~ 새벽 05시',
  },
  {
    month: 4,
    nightTime: '저녁 8시 30분 ~ 새벽 05시',
  },
  {
    month: 5,
    nightTime: '저녁 9시 ~ 새벽 05시',
  },
  {
    month: 6,
    nightTime: '저녁 9시 ~ 새벽 05시',
  },
  {
    month: 7,
    nightTime: '저녁 9시 ~ 새벽 05시',
  },
  {
    month: 8,
    nightTime: '저녁 8시 30분 ~ 새벽 05시',
  },
  {
    month: 9,
    nightTime: '저녁 8시 ~ 새벽 05시',
  },
  {
    month: 10,
    nightTime: '저녁 7시 ~ 새벽 05시',
  },
  {
    month: 11,
    nightTime: '저녁 6시 30분 ~ 새벽 05시',
  },
  {
    month: 12,
    nightTime: '저녁 6시 30분 ~ 새벽 05시',
  },
]

export const AVERAGE_DRIVING_SCORE = 65 // 운전 평균 점수
export const MINIMUM_INSURANCE_COVERAGE_SCORE = 61 // 최소 보험 혜택 대상 점수
