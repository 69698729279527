import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { useCallback, useEffect, useState } from 'react'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import BoxRectangle from '../../assets/images/box_rectangle.svg'
import BoxFocusGraph from '../../assets/images/box_focus_graph.png'

export type BarGraphType = {
  id?: string
  value: number | string
  y: number
  label: number | string
  etcLabel?: string
  barColor?: string
  isActive?: boolean
}

export type BarGraphOption = {
  barWidth?: number
  borderRadius?: number
  unit?: string
  eventLogActionId?: string
  animationReady?: boolean
  isAlwaysVisibleUnit?: boolean
  isItemBottomBoxStyle?: boolean
  eventLog?: () => TmapLogBuilder
  handleClick?: (type: string) => void
}

interface BarGraphProps extends BarGraphOption {
  barGraph: BarGraphType
  index: number
}

function BarGraphItem(props: BarGraphProps) {
  const {
    barGraph,
    borderRadius,
    unit,
    barWidth = 24,
    index,
    isAlwaysVisibleUnit,
    isItemBottomBoxStyle,
    animationReady,
    eventLogActionId,
    eventLog,
    handleClick
  } = props
  const { id, value, y, label, etcLabel, barColor, isActive } = barGraph

  const [axisY, setAxisY] = useState<number>()
  const [init, setInit] = useState(false)

  const handleItemClick = useCallback((id?: string) => {
    if (id && handleClick) {
      if (eventLog && eventLogActionId) {
        eventLog().set('action_id', eventLogActionId).send()
      }
      handleClick(id)
    }
  }, [eventLogActionId, eventLog, handleClick])

  useEffect(() => {
    if (animationReady) {
      setAxisY(y)
      setTimeout(() => setInit(true), 500)
    }
  }, [y, animationReady])

  return (
    <GraphItem
      index={index}
      isActive={isActive}
      barColor={barColor}
      barWidth={barWidth}
      isItemBottomBoxStyle={isItemBottomBoxStyle}
      init={init}
      onClick={() => handleItemClick(id)}
    >
      <GraphItemBar
        borderRadius={borderRadius}
        style={{ top: `${axisY}px` }}
      >
        <GraphItemValue>
          {value}{((unit && isActive) || isAlwaysVisibleUnit) && <span>{unit}</span>}
        </GraphItemValue>
        <GraphItemLabel
          isItemBottomBoxStyle={isItemBottomBoxStyle}
        >
          {isItemBottomBoxStyle ? (
            <>
              {label}
              <strong>{etcLabel}</strong>
            </>
          ) : (
            <>{label}</>
          )}
        </GraphItemLabel>
      </GraphItemBar>
    </GraphItem>
  )
}

export { BarGraphItem }

const GraphItem = styled.li<{ index: number, barWidth: number, isActive?: boolean, barColor?: string, init: boolean, isItemBottomBoxStyle?: boolean }>`
  position: relative;
  z-index: 1;
  width: ${({ barWidth }) => barWidth}px;
  margin: 20px 0px 22px;
  -webkit-tap-highlight-color: transparent;
  
  ${({isItemBottomBoxStyle}) => isItemBottomBoxStyle && css`
    margin: 20px 0 80px;
  `}

  &:nth-of-type(${({ index }) => index + 1}) {
    div {
      transition-delay: ${({ index }) => index * 50}ms;
    }
  }
  ${({ isActive }) => isActive && css`
    em {
      color: var(--C-primary-500);
      font-weight: var(--font-weight-bold);
      
      span {
        background: none !important;
      }
    }
    div {
      background: var(--C-primary-500);
    }
    span {
      font-weight: var(--font-weight-bold);
    }
  `}
  ${({ isActive, isItemBottomBoxStyle }) => isActive && isItemBottomBoxStyle && css`
    span {
      width: 72px;
      background: url(${BoxFocusGraph}) no-repeat 0 100% / 100% 100%;
      color: var(--C-gray-900) !important;
      font-weight: var(--font-weight-normal) !important;
  
      strong {
        color: var(--C-primary-500);
      }
    }
  `}
  ${({ barColor }) => barColor && css`
    div {
      background: ${barColor};
    }
  `}
  ${({ init }) => init && css`
    div {
      transition-delay: 0ms !important;
    }
  `}
`

const GraphItemValue = styled.em`
  position: absolute;
  bottom: calc(100% + 4px); // 4px은 하단 여백
  left: 50%;
  transform: translateX(-50%);
  color: var(--C-gray-400);
  line-height: 1.6rem;
  font-size: var(--font-size-11);
  white-space: nowrap;
`

const GraphItemBar = styled.div<{ borderRadius?: number }>`
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: ${({ borderRadius }) => `${borderRadius}px ${borderRadius}px 0 0`};
  background: var(--C-gray-300);
  transition: .5s;
`

const GraphItemLabel = styled.span<{ isLabelHighlight?: boolean, isItemBottomBoxStyle?: boolean }>`
  position: absolute;
  bottom: -22px;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  color: var(--C-gray-900);
  line-height: 1.6rem;
  font-size: var(--font-size-11);
  white-space: nowrap;
  
  ${({ isItemBottomBoxStyle }) => isItemBottomBoxStyle && css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: -92px;
    width: 56px;
    height: 88px;
    padding: 12px 0;
    background: url(${BoxRectangle}) no-repeat 0 12px / 56px 64px;
    color: var(--C-gray-500);
    line-height: 2rem;
    font-size: var(--font-size-14);
    
    strong {
      margin-top: 2px;
      line-height: 1.8rem;
      font-size: var(--font-size-12);
      font-weight: var(--font-weight-bold);
    }
  `}
`
