import React, { ChangeEvent, useCallback, useRef, useState } from 'react'
import {
  DialogBackground,
  DialogCancelButton,
  DialogFrame,
  DialogCustom,
  DialogFooter,
  DialogOkButton,
} from './Elements'
import { DialogBaseProps } from './types'
import { CSSTransition } from 'react-transition-group'
import { DateTime } from 'luxon'
import styled from '@emotion/styled/dist/emotion-styled.cjs'
import icoCalendar from '../../assets/images/ico_calendar.svg'
import { classNames } from '@tmap-web-lib/utils'

interface Props extends DialogBaseProps {
  title: string
  date: string
  inputTypeMonth?: boolean
}
function DialogDatePicker(props: Props) {
  const { title, date, inputTypeMonth, okText, cancelText, onOk, onCancel } = props

  const backgroundRef = useRef(null)
  const dateRef = useRef<HTMLInputElement | null>(null)

  const [isOpen, setOpen] = useState(true)
  const [dateValue, setDateValue] = useState<string>(date)
  const [isError, setError] = useState(false)

  const onChange = useCallback((e:  ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value
    setDateValue(date)
    setError(DateTime.now().toFormat('yyyy-MM-dd') < date)
  }, [])

  const handleUpdate = useCallback(() => {
    onOk?.(dateValue)
    setOpen(false)
  }, [dateValue, onOk])

  return (
    <CSSTransition
      classNames="dimmed-fade-in"
      unmountOnExit={true}
      timeout={300}
      in={isOpen}
      nodeRef={backgroundRef}
      appear
    >
      <DialogBackground ref={backgroundRef}>
        <DialogFrame
          className={'default'}
          content={
            <DialogCustom
              title={title}
              content={
                <DialogForm
                  htmlFor="datepicker"
                  className={classNames(!dateValue ? 'none' : '')}
                >
                  {dateValue ? (
                    DateTime.fromISO(dateValue).toFormat(inputTypeMonth ? 'yyyy년M월' : 'yyyy년M월d일')
                  ) : (
                    <>날짜선택</>
                  )}
                  <DialogFormInput
                    ref={dateRef}
                    type={inputTypeMonth ? 'month' : 'date'}
                    id="datepicker"
                    value={dateValue ? DateTime.fromISO(dateValue).toFormat(inputTypeMonth ? 'yyyy-MM' : 'yyyy-MM-dd') : ''}
                    max={DateTime.now().toFormat(inputTypeMonth ? 'yyyy-MM' : 'yyyy-MM-dd')}
                    onChange={(e) => onChange(e)}
                  />
                  {isError && <DialogFormInputError>오늘 이후의 날짜를 입력할 수 없습니다.</DialogFormInputError>}
                </DialogForm>
              }
            />
          }
          buttonSet={
            <DialogFooter
              cancelButton={
                <DialogCancelButton
                  borderRadius={'0'}
                  onClick={onCancel}
                >
                  {cancelText}
                </DialogCancelButton>
              }
              okButton={
                <DialogOkButton
                  borderRadius={'0'}
                  gap={'0'}
                  disabled={isError}
                  onClick={handleUpdate}
                >
                  {okText ?? '입력'}
                </DialogOkButton>
              }
            />
          }
        />
      </DialogBackground>
    </CSSTransition>
  )
}

export default DialogDatePicker
export { DialogDatePicker }

const DialogForm = styled.label`
  display: block;
  position: relative;
  height: 60px;
  margin: 10px 48px 24px;
  border-bottom: 2px solid var(--C-gray-300);
  box-sizing: border-box;
  background: var(--C-white);
  line-height: 6rem;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-24);
  white-space: nowrap;
  
  @media screen and (max-width: 320px) {
    margin-right: 32px;
    margin-left: 32px;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 28px;
    height: 28px;
    margin: auto 0;
    background: var(--C-white) url(${icoCalendar}) no-repeat 0 0 / 100% 100%;
  }
  &.none {
    color: var(--C-gray-400);
    font-weight: var(--font-weight-normal);
  }
`
const DialogFormInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 100;
  appearance: none;

  &::-webkit-calendar-picker-indicator {
    width: 100%;
    height: 100%;
  }
`
const DialogFormInputError = styled.p`
  margin-top: 8px;
  color: var(--C-red-400);
  font-size: var(--font-size-12);
  line-height: 1.8rem;
  white-space: normal;
  word-break: keep-all;
`
