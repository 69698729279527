import { maxBy } from 'lodash-es'

export type BarGraphItemType = {
  yearMonth: string,
  duration: number,
}

type Options = {
  referenceTime: number
  barMaxLength: number
  barDefaultLength: number
}

type ChartItem = {
  id: string
  length: string | number
}

function getBarGraphData(data: BarGraphItemType[], options: Options) {
  const { referenceTime, barMaxLength, barDefaultLength } = options
  const maxItem = maxBy(data, 'duration')
  const chart: ChartItem[] = []

  if (!maxItem) return
  if (maxItem.duration >= referenceTime) {
    const { yearMonth, duration } = maxItem
    data.forEach((item) => {
      const rate = (item.duration / duration) * 100
      chart.push({
        id: item.yearMonth,
        length: yearMonth === item.yearMonth ? barMaxLength : (((barMaxLength - barDefaultLength) * rate) / 100) + barDefaultLength,
      })
    })
  } else {
    data.forEach((item) => {
      const rate = (item.duration / referenceTime) * 100
      chart.push({
        id: item.yearMonth,
        length: (((barMaxLength - barDefaultLength) * rate) / 100) + barDefaultLength,
      })
    })
  }
  return chart.reverse()
}

export { getBarGraphData }
