import { createSelector } from '@reduxjs/toolkit'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useBlockHistory, useDialogManager } from '@tmap-web-lib-close/dialog-manager/react-router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  DialogOk,
  DialogSingleTerms,
  DialogWithAnimation,
  Header,
  ScrollTop,
} from '../../components'
import {
  CarProfile,
  CarProfileListResData,
  TripsListTripType,
} from '@tmap-web-lib/remote-api-client/frontman'
import { RootState, store } from '../../store'
import {
  TrackingValues,
  useLogBox,
  useOpenServiceUrl,
  useAdsManager,
  useGetRedDotList,
  useGetNewAccessKey,
} from '../../hooks'
import {
  MAIN_LOG_PRESET,
  MAIN_NEWCOMER_LOG_PRESET,
  MAIN_POPUP_LOG_PRESET,
} from '../../hooks/logbox/preset'
import { usePageIdContext } from '../../providers/page-id-context'
import {
  setIsCarLifeTermsAgreed,
  setIsSkeleton,
  setMainPopupSuppressed,
  setSchemeURL,
  setOptionalMarketingTermsStatus,
} from '../../store/settings'
import {
  MainAdvertisementBanner,
  MainBenefitBanner,
  MainAllBenefitList,
  MainTips,
  MainPersonalizedMessage,
  MainRecentDriving,
  MainSkeleton,
  MainHeaderSkeleton,
  MainTermsNotAllowed,
  MainUserStatus,
  MainCarInfoNone,
  MainCarInfoError,
  MainCarInfo,
  MainEmergencyCall,
  MainCharanchaMedia,
  MainBenefitList,
  MainCharanchaCarList,
  MainSettings,
  MainRank,
} from './index'
import { clearError } from '../../store/app'
import debounce from 'lodash-es/debounce'
import DialogGeneralTerms from '../../components/Dialog/DialogGeneralTerms'
import {
  useGetAdsServing,
  useGetCarProfileList,
  useGetScoreContentsList,
  useGetTermsAgreement,
  useGetTripSafeDrivingInfo,
  useGetUserMessages,
  useUpdateCarProfile,
  useGetAdsTracking,
  useGetCarOptionCodeList,
  useGetUserRedDotList,
  useGetDrivingScoreReports,
  useGetRecentlyViewedCarList,
  useGetUserRanks,
  useGetCharanchaMediaList,
  useGetCharanchPopularCarList,
} from '../../react-query'
import { focusManager } from '@tanstack/react-query'
import { MainBenefitPopular } from './MainBenefitPopular'
import { useGetBenefitData } from '../Benefit/hooks'
import { AdType } from '@tmap-web-lib/remote-api-client/frontman'
import UrlParse from 'url-parse'
import { gte } from 'semver'
import { DateTime } from 'luxon'
import { shuffle } from 'lodash-es'
import isEmpty from 'lodash-es/isEmpty'
import { OptionalMarketingTermsItem, useOptionalTerms } from './hooks'
import { STORAGE_KEY_LIFE_OPTIONAL_MARKETING_TERMS_STATUS } from '../../store/settings/storage-keys'

export const controller = new AbortController()

function Main() {
  const { euk, tabUiModeEnabled, mainPopupSuppressed, schemeURL, isCarLifeTermsAgreed } = useSelector(selectMainState)
  const getNewAccessKey = useGetNewAccessKey()

  const [trackingUrl, setTrackingUrl] = useState('')
  const [vimpressionId, setVimpressionId] = useState('')
  const [materialId, setMaterialId] = useState('')
  const [orientation, setOrientation] = useState(1)
  const [isMainPopupClicked, setIsMainPopupClicked] = useState(false)
  const [isVimpValuesReady, setIsVimpValuesReady] = useState(false)
  const [isHideIntroBanner, setHideIntroBanner] = useState(false)
  const [mainBannerData, setMainBannerData] = useState<AdType[] | null>(null)
  const [mainPopupBannerData, setMainPopupBannerData] = useState<AdType[] | null>(null)
  const [charanchaType, setCharanchaType] = useState<string | undefined>(undefined)
  const [isResumed, setIsResumed] = useState(false)

  const { data: carLifeTermsAllow, refetch: refetchCarLifeTermsAllow } = useGetTermsAgreement({
    termsType: 'UBS01',
    termsAllowCode: 'UTERMS-B2C-1',
  })
  const { refetch: refetchDataPolicyAgreement } = useGetTermsAgreement({
    termsType: 'UBS01',
    termsAllowCode: 'UTERMS-B2C-5',
    enabled: isCarLifeTermsAgreed === true && isResumed,
  })
  const { refetch: refetchUnpaidTollsAgreement } = useGetTermsAgreement({
    termsType: 'TMS01',
    termsAllowCode: 'TTERMS-B2C-18',
    enabled: isCarLifeTermsAgreed === true && isResumed,
  })
  const {
    optionalTermsAgreement,
    refetchOptionalTermsAgreement,
    optionalTermsAgreementError,
    optionalTermsGroupData,
    optionalTermsCode,
    optionalTermsList,
    optionalTerms,
  } = useOptionalTerms({ isCarLifeTermsAgreed: isCarLifeTermsAgreed === true })
  const {
    data: carProfileListData,
    isInitialLoading: isCarProfileListDataLoading,
    refetch: refetchCarProfileList,
    error: carProfileListError,
    isError: isCarProfileListError,
  } = useGetCarProfileList({ useErrorBoundary: false, enabled: isCarLifeTermsAgreed === true })
  const {
    data: drivingScoreData,
    isInitialLoading: isDrivingScoreLoading,
    refetch: refetchDrivingScoreData,
  } = useGetDrivingScoreReports()
  const {
    data: userMessageData,
    isInitialLoading: isUserMessageLoading,
    refetch: refetchUserMessageData,
    isRefetching,
  } = useGetUserMessages({ enabled: isCarLifeTermsAgreed === true, useErrorBoundary: false })
  const {
    data: recentDrivingInfo,
    isInitialLoading: isRecentDrivingHistoryLoading,
    refetch: refetchRecentDrivingInfo,
  } = useGetTripSafeDrivingInfo({ lastMonth: 12 }, { enabled: isCarLifeTermsAgreed === true, useErrorBoundary: false })
  const {
    data: userRanks,
    isInitialLoading: isUserRanksLoading,
    refetch: refetchUserRanks,
  } = useGetUserRanks({ useErrorBoundary: false })
  const {
    data: scoreContent,
    isInitialLoading: isScoreContentLoading,
  } = useGetScoreContentsList({ pageNo: 1, pageSize: 3, useErrorBoundary: false, boardCode: 'BOARD-SCORE' })
  const { data: mainBanners, isInitialLoading: isMainBannersLoading } = useGetAdsServing({
    inventoryCodes: 'IN401,IN402',
    useErrorBoundary: false,
  })
  const {
    data: carOptionCodeList,
    isInitialLoading: isCarOptionCodeListLoading,
    isError: isCarOptionCodeListError,
  } = useGetCarOptionCodeList({ type: '200', useErrorBoundary: false })
  const { data: charanchaMedia, isInitialLoading: isCharanchaMediaLoading } = useGetCharanchaMediaList({
    size: 8,
    type: charanchaType,
    useErrorBoundary: false,
    enabled: charanchaType !== undefined,
  })
  const { data: recentlyViewedCarList, isInitialLoading: isRecentlyViewedCarListLoading, refetch: refetchRecentlyViewedCarList} = useGetRecentlyViewedCarList({ useErrorBoundary: false })
  const { data: popularCarList, isInitialLoading: isPopularCarListLoading} = useGetCharanchPopularCarList({ useErrorBoundary: false })
  const { data: tooltips, isInitialLoading: isTooltipsLoading } = useGetUserRedDotList({
    itemGroup: 'DRIVING_LIFE_SERVICE',
    useErrorBoundary: false,
  })
  const { redDotList, redDotLog, getRedDotList, updateRedDotList } = useGetRedDotList()
  const { initTrackingValues, handleBanner } = useAdsManager()
  const { refetch: refetchAdsTracking } = useGetAdsTracking({
    enabled: isVimpValuesReady,
    url: trackingUrl,
    orientation: orientation,
    materialId: materialId,
    vimpressionId: vimpressionId
  })
  const { benefitData, benefitList, mainBenefitList, isCategoryLoading, isBenefitLoading } = useGetBenefitData({ type: 'MAIN', useErrorBoundary: false })
  const { mutate: updateCarProfile } = useUpdateCarProfile()
  const history = useHistory()
  const openServiceUrl = useOpenServiceUrl()
  const blockHistory = useBlockHistory()
  const dialogManager = useDialogManager()
  const pageIdContext = usePageIdContext()
  const [mainEventLog, mainExposeLog] = useLogBox(['event', 'expose'], {
    attributesPreset: MAIN_LOG_PRESET.ATTRIBUTES,
    customFieldsPreset: MAIN_LOG_PRESET.CUSTOM_FIELDS,
    withEuk: true
  })
  const [newComerEventLog, newComerExposeLog] = useLogBox(['event', 'expose'], {
    attributesPreset: MAIN_NEWCOMER_LOG_PRESET.ATTRIBUTES,
    customFieldsPreset: MAIN_NEWCOMER_LOG_PRESET.CUSTOM_FIELDS,
    withEuk: true
  })
  const [mainPopupEventLog, mainPopupExposeLog] = useLogBox(['event', 'expose'], {
    attributesPreset: MAIN_POPUP_LOG_PRESET.ATTRIBUTES,
    customFieldsPreset: MAIN_POPUP_LOG_PRESET.CUSTOM_FIELDS,
    withEuk: true
  })

  const [recentSingleDrivingInfo, setRecentSingleDrivingInfo] = useState<TripsListTripType | null>(null)
  const [isMainDataLoading, setIsMainDataLoading] = useState(true)
  const [carProfile, setCarProfile] = useState<CarProfile | null | undefined>(undefined)
  const [hasAdLandingUrl, setHasAdLandingUrl] = useState(false)

  const popularCarListShuffle = useMemo(() => {
    if (!popularCarList) return
    return {_embedded: {cars: [...shuffle(popularCarList._embedded.cars).slice(0, 10)]}}
  }, [popularCarList])

  const [eventLog, exposeLog] = useMemo(() => {
    if (isCarLifeTermsAgreed) {
      return [mainEventLog, mainExposeLog]
    } else {
      return [newComerEventLog, newComerExposeLog]
    }
  }, [isCarLifeTermsAgreed, mainExposeLog, mainEventLog, newComerExposeLog, newComerEventLog])

  const fetchVehicleProfile = useCallback( (data: CarProfileListResData) => {
    if (data) {
      const list = data.integrateCarInfos || null

      if (list) {
        const reverse = [...list].reverse()
        const result = reverse.find((item) => item.tscoreYn === 'Y') || reverse.find((item) => !!item.extCarInfo)

        setCarProfile(result || null)
        if (result && result.tscoreYn !=='Y') {
          const { carProfileId, mainYn } = result
          updateCarProfile({ carProfileId, mainYn, tscoreYn: 'Y' })
        }
      }
    }
  }, [updateCarProfile])

  const handleHeaderGoBackClick = useCallback(() => {
    eventLog().set('action_id', 'tap.back').send()
    TmapApp.onBackKeyPressed()
  }, [eventLog])

  const handleBannerClick = useCallback((ad: AdType) => {
    if (ad.landingUrl) {
      setHasAdLandingUrl(true)
      handleBanner(ad, mainPopupEventLog, 'tap.banner')
    } else {
      eventLog().set('action_id', 'tap.banner').addCustomField('ad_code', ad.materials[0].id).send()
    }
  }, [mainPopupEventLog, handleBanner, eventLog])

  const showMainPopup = useCallback(() => {
    dialogManager.showDialog({
      component: DialogWithAnimation,
      props: {
        wrapperClassName: 'layer_wrap_main',
        customClassName: 'no_padding',
        footerClassName: 'no_padding',
        animationType: 'slide-up-center',
        cancelText: '일주일간 보지 않기',
        okText: '닫기',
        onOk() {
          setHideIntroBanner(true)
          if (mainPopupBannerData && mainPopupBannerData[0].materials[0].id) {
            mainPopupEventLog().set('action_id', 'tap.close').addCustomField('banner_code', mainPopupBannerData[0].materials[0].id).send()
          }
        },
        onCancel() {
          setHideIntroBanner(true)
          if (mainPopupBannerData && mainPopupBannerData[0].materials[0].id) {
            mainPopupEventLog().set('action_id', 'tap.block').addCustomField('banner_code', mainPopupBannerData[0].materials[0].id).send()
            store.dispatch(setMainPopupSuppressed(true))
          }
        },
        onContentClick: () => {
          if (mainPopupBannerData) {
            const adItem = mainPopupBannerData[0]
            const trackingInfo = adItem?.trackingEventUrls.find(trackingInfo => trackingInfo.key === 'click')
            if (trackingInfo) {
              setTrackingUrl(trackingInfo.value)
              setIsMainPopupClicked(true)
              handleBannerClick(adItem)
            }
          }
        },
        content:
          <>
            {
              mainPopupBannerData && mainPopupBannerData[0].materials[0].url &&
              <img src={mainPopupBannerData && mainPopupBannerData[0].materials[0].url} className="popup_cont_image" alt=""/>
            }
          </>
      }
    }, {
      onClosedByDismiss: (type) => {
        if (type === 'OUTSIDE') {
          setHideIntroBanner(true)
        }
      }
    })
  }, [dialogManager, mainPopupBannerData, mainPopupEventLog, handleBannerClick])

  const callShowTermsPopupDebouncing = useMemo(() => debounce(() => {
    dialogManager.showDialog({
      component: DialogGeneralTerms,
      props: {
        title: '운전점수 생성을 위한\n약관에 동의하시나요?',
        onOk: () => false,
        onCancel: () => true,
        isDialogOpen: false,
      },
    })
  }, 500, { leading: true, trailing: false }), [dialogManager])

  const showTermsPopup = useCallback(() => {
    callShowTermsPopupDebouncing()
  }, [callShowTermsPopupDebouncing])

  const handleErrorDialog = useCallback(() => {
    dialogManager.showDialog(
      {
        component: DialogOk,
        props: {
          title: <>일시적으로 서비스를<br/> 이용할 수 없습니다.<br/> <p className="sub">앱을 다시 실행해주세요.</p></>,
          onOk() {
            store.dispatch(clearError())
            return true
          }
        },
      }
    )
  }, [dialogManager])

  const showSingleTermsPopup = useCallback(() => {
    if (optionalTermsGroupData) {
      const { cancelBtnText } = JSON.parse(optionalTermsGroupData.additionalData)
      dialogManager.showDialog({
        component: DialogSingleTerms,
        props: {
          optionalTermsGroupData,
          wrapperClassName: 'layer_wrap_forward',
          title: '운전점수 혜택 제공을 위한\n마케팅 수신에 동의해주세요',
          cancelText: cancelBtnText,
          okText: optionalTermsGroupData.agreeBtnTitle,
          footerClassName: 'single_terms',
          onOk:  () => {
            handleErrorDialog()
          },
          onCancel: async () => {
            const updateDate = DateTime.now().toFormat('yyyyMMdd')

            if (optionalTerms) {
              optionalTerms.count++
              optionalTerms.updateDate = updateDate
              store.dispatch(setOptionalMarketingTermsStatus(JSON.stringify([...optionalTermsList])))
            } else {
              store.dispatch(setOptionalMarketingTermsStatus(JSON.stringify([...optionalTermsList, { termsCode: optionalTermsCode, updateDate: updateDate, count: 1 }])))
            }
          }
        },
      }, {
        useCloseOnOutsideClick: false,
      })
    }
  }, [dialogManager, handleErrorDialog, optionalTermsGroupData, optionalTermsList, optionalTerms, optionalTermsCode])

  const handleClickBenefit = useCallback(() => {
    if (isCarLifeTermsAgreed) {
      eventLog().set('action_id', 'tap.popular_benefits').send()
    } else {
      newComerEventLog().set('action_id', 'tap.popular_benefits').send()
    }
    openServiceUrl('tmap://life?pageid=benefit_popular')
  }, [isCarLifeTermsAgreed, openServiceUrl, eventLog, newComerEventLog])

  // 운점점수 차량이 없고 운전점수를 통하지 않은 상태에서 차량을 등록하고 운전점수로 왔을 때 원부등록된 마지막 차량을 운전점수 차량으로 등록 후 개인화메세지가 api가 마지막으로 갱신되도록 적용
  const refetchAllWrap = useCallback(async () => {
    await refetchCarProfileList().then(({ data }) => {
      if (data) {
        fetchVehicleProfile(data)
      }
    })
    await Promise.all([
      refetchDrivingScoreData(),
      refetchRecentDrivingInfo(),
      refetchUserMessageData(),
      getRedDotList(),
      refetchRecentlyViewedCarList(),
      refetchUserRanks(),
    ]).then(() => focusManager.setFocused(undefined))
  }, [
    fetchVehicleProfile,
    refetchDrivingScoreData,
    refetchUserMessageData,
    refetchRecentDrivingInfo,
    refetchCarProfileList,
    getRedDotList,
    refetchRecentlyViewedCarList,
    refetchUserRanks,
  ])

  const refetchAllData = useCallback(() => {
    refetchAllWrap()
  }, [refetchAllWrap])

  const handleSuccessProgressOnResume = useCallback(() => {
    refetchAllData()
  }, [refetchAllData])

  const handleSuccessProgress = useCallback(() => {
    if (optionalTermsAgreement && optionalTermsAgreement.termsAgreements && optionalTermsAgreement.termsAgreements[0].allowYn !== 'Y') {
      // 신규 가입자가 스킴으로 서브페이지 진입 > 카라이프 필수약관만 동의 후 메인 진입 시 선택 약관이 재표출되는 이슈로 store에서 가져오지를 못하여 로컬스토리지를 직접 가져 옮(TMAPWEB-2795)
      const storage = localStorage.getItem(STORAGE_KEY_LIFE_OPTIONAL_MARKETING_TERMS_STATUS)
      const termsList: OptionalMarketingTermsItem[] = storage ? JSON.parse(storage) : []
      const terms = termsList.find((item) => item.termsCode === optionalTermsCode)

      if (!terms) {
        showSingleTermsPopup()
      } else {
        const { updateDate, count } = terms
        const currentDate = DateTime.fromISO(DateTime.now().toFormat('yyyyMMdd'))
        const prevUpdatedDate = DateTime.fromISO(updateDate)
        const diffDays = currentDate.diff(prevUpdatedDate, 'days').toObject().days!

        if (optionalTermsGroupData) {
          const { targetDate, targetCount } = JSON.parse(optionalTermsGroupData.additionalData)
          if (diffDays >= targetDate && count < targetCount) {
            showSingleTermsPopup()
          }
        }
      }
    }
    if (optionalTermsAgreementError) {
      handleErrorDialog()
    }
  }, [optionalTermsAgreement, optionalTermsAgreementError, optionalTermsGroupData, optionalTermsCode, showSingleTermsPopup, handleErrorDialog])

  const handleCarLifeTermsAgreement = useCallback((onSuccess: () => void) => {
    if (isCarLifeTermsAgreed) {
      onSuccess()
    }
  }, [isCarLifeTermsAgreed])

  const handleRefetchCarLifeTerms = useCallback((onSuccess: () => void) => {
    refetchCarLifeTermsAllow().then((agreement) => {
      if (agreement && agreement.data && agreement.data.termsAgreements) {
        if (agreement.data.termsAgreements[0].allowYn === 'Y') {
          onSuccess()
        } else {
          Promise.all([
            refetchDataPolicyAgreement(),
            refetchUnpaidTollsAgreement(),
            optionalTermsCode && refetchOptionalTermsAgreement(), // 선택 약관(텔레마케팅) 코드가 있다면 재조회
          ]).then(() => {
            onSuccess()
          })
        }
      }
    }).catch(() => {
      handleErrorDialog()
    })
  }, [refetchCarLifeTermsAllow, refetchDataPolicyAgreement, refetchUnpaidTollsAgreement, refetchOptionalTermsAgreement, optionalTermsCode, handleErrorDialog])

  const handleOnResume = useCallback(async () => {
    await getNewAccessKey()
    await handleRefetchCarLifeTerms(handleSuccessProgressOnResume)
  }, [getNewAccessKey, handleRefetchCarLifeTerms, handleSuccessProgressOnResume])

  const setTrackingData = useCallback((data: TrackingValues) => {
    setTrackingUrl(data.trackingUrl)
    setOrientation(data.orientation)
    setVimpressionId(data.vimpressionId!)
    setMaterialId(data.materialId)
  },[])

  useEffect(() => {
    if (gte(TmapApp.env.appVersion, '10.5.1')) {
      setCharanchaType('TSCORE_INAPP')
    } else {
      setCharanchaType('TSCORE')
    }
  },[])

  useEffect(() => {
    // 불필요해진 로컬 스토리지 제거
    localStorage.removeItem('LIFE-STORE-PERSONALIZED-MESSAGE')
    localStorage.removeItem('LIFE-STORE-TM-TERMS-UPDATE-DATA')
  },[])

  useEffect(() => {
    return TmapApp.utils.addNativeEventListener('onRefresh', () => {
      window.history.scrollRestoration = "manual"
    })
  }, [])

  useEffect(() => {
    const { extraObject, pageIdInfo } = pageIdContext
    // scroll_to 같은 파라미터에 다른 페이지들이 영향받지 않도록 로그정보만 적용시켜줌
    const encodedLogBoxObject = encodeURIComponent(JSON.stringify({ activeArea: extraObject.activeArea, logbox: extraObject.logbox }))
    // 로컬에서 pageid로 접근할 때 history.goBack() 또는 Main으로 replace 되는 것을 막기 위함
    if (TmapApp.env.appVersion !== '0.0.0') {
      // 운전점수 메인이 항상 떠있는 웹뷰라 스킴으로 진입했을 때 히스토리가 쌓이거나 밑에 메인 페이지가 한 번 떠 깔리는 이슈 있음
      // AOS의 경우 replace 해도 히스토리가 쌓이는 이슈 있어 로그 정보를 가지고있는 url을 스토어에 저장 후 history.back으로 돌아가주고 (0번째 페이지로 가기 위함)
      // 저장된 url이 있으면 replace 해주도록해서 히스토리가 쌓이지 않도록 적용
      if (TmapApp.env.isAndroid) {
        if (pageIdInfo) {
          let path = `/web?extra=${encodedLogBoxObject}`
          store.dispatch(setSchemeURL(path))
          window.history.back()
        }
        if (schemeURL) {
          window.location.replace(schemeURL)
          setTimeout(() => store.dispatch(setSchemeURL('')), 0)
        }
      } else {
        if (pageIdInfo) {
          window.location.replace(`/web?extra=${encodedLogBoxObject}`)
        }
      }
    }
  }, [schemeURL, pageIdContext])

  useEffect(() => {
    if (isCarLifeTermsAgreed === true) {
      if (
        !isDrivingScoreLoading &&
        !isCarProfileListDataLoading &&
        !isRecentDrivingHistoryLoading &&
        !isUserMessageLoading &&
        !isScoreContentLoading &&
        !isCategoryLoading &&
        !isBenefitLoading &&
        !isCarOptionCodeListLoading &&
        !isCharanchaMediaLoading &&
        !isRecentDrivingHistoryLoading &&
        !isPopularCarListLoading &&
        !isTooltipsLoading &&
        !isUserRanksLoading
      ) {
        setIsMainDataLoading(false)
      }
    } else if (isCarLifeTermsAgreed === false) {
      setIsMainDataLoading(false)
    }
  }, [
    isCarLifeTermsAgreed,
    isDrivingScoreLoading,
    isCarProfileListDataLoading,
    isRecentDrivingHistoryLoading,
    isUserMessageLoading,
    isScoreContentLoading,
    isCategoryLoading,
    isBenefitLoading,
    isCarOptionCodeListLoading,
    isCharanchaMediaLoading,
    isRecentlyViewedCarListLoading,
    isPopularCarListLoading,
    isTooltipsLoading,
    isUserRanksLoading,
  ])

  useEffect(() => {
    if (carLifeTermsAllow && carLifeTermsAllow.termsAgreements) {
      store.dispatch(setIsCarLifeTermsAgreed(carLifeTermsAllow.termsAgreements[0].allowYn === 'Y'))
    }
  },[carLifeTermsAllow])

  useEffect(() => {
    return TmapApp.utils.addNativeEventListener('onResume', () => {
      handleOnResume()
      focusManager.setFocused(true)
      setIsResumed(true)
      store.dispatch(setIsSkeleton(false))
      setTimeout(() => store.dispatch(setIsSkeleton(true)),1500)
    })
  }, [handleOnResume, history, pageIdContext.pageId])

  useEffect(() => {
    // history back 발생하면 웹뷰 닫기.
    return blockHistory({
      location: { ...history.location },
      onBlock(location, action) {
        return action === 'POP'
      },
      onBlocked() {
        handleHeaderGoBackClick()
      },
    })
  }, [blockHistory, history, handleHeaderGoBackClick])

  useEffect(() => {
    const extraCustomFields = pageIdContext.extraObject.logbox
    if (extraCustomFields) {
      const { origin, type } = extraCustomFields
      if (isCarLifeTermsAgreed !== null && redDotLog !== null && carProfile !== undefined) {
        // isCarLifeTermsAgreed의 초기 값은 null. 약관 동의 여부가 확실히 false일 때에 미동의자 로그 보냄
        if (carLifeTermsAllow && carLifeTermsAllow.termsAgreements && carLifeTermsAllow.termsAgreements[0].allowYn === 'Y') {
          const insRegDate = carProfile?.extCarInfo && carProfile?.insurance?.insRegDate
          let customLogValue = 'no_wonbu'

          if (!!insRegDate) {
            if (DateTime.now().plus({ month: 1 }).month === DateTime.fromISO(insRegDate).month) {
              customLogValue = 'insur_previous_month'
            } else if (DateTime.now().month === DateTime.fromISO(insRegDate).month) {
              customLogValue = 'insur_expiration_month'
            } else {
              customLogValue = 'insur_not_target_period'
            }
          }
          Object.keys(extraCustomFields).forEach(key => {
            MAIN_LOG_PRESET.CUSTOM_FIELDS.push([key, extraCustomFields[key]])
            MAIN_LOG_PRESET.CUSTOM_FIELDS.push(['benefit_banner_msg', customLogValue])
          })
          TmapApp.recordEvent({
            name: 'drivingscore_main',
            json: { euk: euk, af_mkt_channel: origin, af_mkt_promotion: type }
          })
        } else if (carLifeTermsAllow && carLifeTermsAllow.termsAgreements && carLifeTermsAllow.termsAgreements[0].allowYn === 'N') {
          Object.keys(extraCustomFields).forEach(key => {
            MAIN_NEWCOMER_LOG_PRESET.CUSTOM_FIELDS.push([key, extraCustomFields[key]])
          })
          TmapApp.recordEvent({
            name: 'drivingscore_main_newcomer',
            json: { euk: euk, af_mkt_channel: origin, af_mkt_promotion: type }
          })
        }
        exposeLog().addCustomField('reddot', redDotLog).send()
      }
    }
  }, [pageIdContext, isCarLifeTermsAgreed, exposeLog, euk, carLifeTermsAllow, redDotLog, carProfile])

  useEffect(() => {
    handleCarLifeTermsAgreement(handleSuccessProgress)
  }, [handleCarLifeTermsAgreement, handleSuccessProgress])

  useEffect(() => {
    if (mainBanners?.ads?.length) {
      setMainBannerData(mainBanners.ads.filter((data) => data.inventoryCode === 'IN402'))
      setMainPopupBannerData(mainBanners.ads.filter((data) => data.inventoryCode === 'IN401'))
    }
  }, [mainBanners])

  useEffect(() => {
    if (!mainPopupSuppressed &&
      !isMainDataLoading &&
      mainPopupBannerData
      && mainPopupBannerData.length >= 1
    ) {
      initTrackingValues(mainPopupBannerData[0]).then((data) => {
        if (data) {
          setTrackingData(data)
        }
      })
    }
  }, [mainPopupSuppressed, mainPopupBannerData, isMainDataLoading, initTrackingValues, setTrackingData])

  useEffect(() => {
    if (trackingUrl && orientation && materialId && vimpressionId) {
      setIsVimpValuesReady(true)
    }
  }, [trackingUrl, orientation, materialId, vimpressionId])

  useEffect(() => {
    if (isVimpValuesReady) {
      const isVimpUrl = new UrlParse(trackingUrl).pathname.includes('vimp')
      if (isVimpUrl) {
        refetchAdsTracking().then(() => {
          setIsVimpValuesReady(false)
          if (!isMainPopupClicked) {
            mainPopupBannerData && mainPopupExposeLog().addCustomField('banner_code',mainPopupBannerData[0].materials[0].id ).send()
            showMainPopup()
          }
        })
      }
    }
  }, [isVimpValuesReady, trackingUrl, refetchAdsTracking, isMainPopupClicked, showMainPopup, mainPopupBannerData, mainPopupExposeLog])

  useEffect(() => {
    if (trackingUrl.includes('click') && hasAdLandingUrl) {
      refetchAdsTracking()
    }
  },[trackingUrl, hasAdLandingUrl, refetchAdsTracking])

  useEffect(() => {
    if (recentDrivingInfo) {
      if (recentDrivingInfo.trips.length) {
        setRecentSingleDrivingInfo({ ...recentDrivingInfo.trips[0]})
      } else {
        setRecentSingleDrivingInfo(null)
      }
    }
  }, [recentDrivingInfo])

  useEffect(() => {
    if (carProfileListData) {
      const list = carProfileListData.integrateCarInfos || null

      if (list) {
        const reverse = [...list].reverse()
        const result = reverse.find((item) => item.tscoreYn === 'Y') || reverse.find((item) => !!item.extCarInfo)

        setCarProfile(result || null)
        if (result && result.tscoreYn !=='Y') {
          const { carProfileId, mainYn } = result
          updateCarProfile({ carProfileId, mainYn, tscoreYn: 'Y' })
        }
      }
    }
  },[carProfileListData, updateCarProfile])

  useEffect(() => {
    setHideIntroBanner(!mainPopupBannerData || !!mainPopupSuppressed)
  }, [mainPopupBannerData, mainPopupSuppressed])

  return (
    <>
      <ScrollTop />
      {!tabUiModeEnabled && (
        <Header
          eventLog={eventLog}
          isDefaultType={false}
          isForLowSpec={true}
        />
      )}
      <div className="contents">
        {isMainDataLoading ? (
          <MainHeaderSkeleton/>
        ) : (
          <section
            className={`main_header ${(isCarLifeTermsAgreed && !carProfileListError && !carProfile?.extCarInfo) ? 'expand' : ''}`}
          >
            {carLifeTermsAllow !== undefined &&
              <>
                {isCarLifeTermsAgreed ? (
                  <>
                    <MainUserStatus
                      drivingScoreData={drivingScoreData}
                      redDotList={redDotList}
                      updateRedDotList={updateRedDotList}
                      redDotLog={redDotLog}
                      eventLog={eventLog}
                      showTermsPopup={showTermsPopup}
                    />
                    {!isCarProfileListDataLoading && carProfileListError ? (
                      <MainCarInfoError/>
                    ) : (
                      (carProfile?.extCarInfo) ? (
                        <MainCarInfo
                          carProfile={carProfile}
                          redDotList={redDotList}
                          carOptionCodeList={carOptionCodeList}
                          isHideIntroBanner={isHideIntroBanner}
                          redDotLog={redDotLog}
                          eventLog={eventLog}
                        />
                      ) : (
                        <MainCarInfoNone eventLog={eventLog} />
                      )
                    )}
                  </>
                ) : (
                  <MainTermsNotAllowed eventLog={eventLog} handleTermsCheck={showTermsPopup}/>
                )}
              </>
            }
          </section>
        )}
        <section className="main_content">
          {isMainDataLoading ? (
            <MainSkeleton/>
          ) : (
            carLifeTermsAllow !== undefined &&
            <>
              {isCarLifeTermsAgreed && (
                <>
                  <MainPersonalizedMessage
                    userMessageData={userMessageData}
                    isRefetching={isRefetching}
                    redDotLog={redDotLog}
                    eventLog={eventLog}
                  />
                  <MainBenefitBanner
                    benefitData={benefitData}
                    drivingScoreData={drivingScoreData}
                    insRegDate={carProfile?.insurance?.insRegDate}
                    eventLog={eventLog}
                  />
                  <MainRecentDriving
                    data={recentSingleDrivingInfo}
                    eventLog={eventLog}
                  />
                  <MainCharanchaCarList
                    data={recentlyViewedCarList}
                    type={'RECENT'}
                    inactive={isEmpty(recentlyViewedCarList)}
                    eventLog={eventLog}
                  />
                  <MainCharanchaCarList
                    data={popularCarListShuffle}
                    type={'RECOMMEND'}
                    inactive={!isEmpty(recentlyViewedCarList)}
                    eventLog={eventLog}
                  />
                  <MainBenefitList
                    data={benefitList}
                    drivingScoreData={drivingScoreData}
                    eventLog={eventLog}
                    redDotLog={redDotLog}
                  />
                  <MainAllBenefitList
                    data={mainBenefitList}
                    drivingScoreData={drivingScoreData}
                    eventLog={eventLog}
                    redDotLog={redDotLog}
                  />
                  <MainRank
                    data={userRanks}
                    eventLog={eventLog}
                  />
                </>
              )}
              <MainCharanchaMedia
                data={charanchaMedia}
                eventLog={eventLog}
              />
              <MainAdvertisementBanner
                data={mainBannerData}
                isLoading={isMainBannersLoading}
                eventLog={eventLog}
                handleTermsCheck={showTermsPopup}
              />
              <MainTips
                data={scoreContent}
                eventLog={eventLog}
              />
              {isCarLifeTermsAgreed && (
                <>
                  <MainEmergencyCall
                    carProfile={carProfile}
                    carOptionCodeList={carOptionCodeList}
                    isCarProfileListError={isCarProfileListError}
                    isCarOptionCodeListError={isCarOptionCodeListError}
                    eventLog={eventLog}
                  />
                  <MainSettings eventLog={eventLog}/>
                </>
              )}
              <MainBenefitPopular
                data={tooltips}
                handleClick={handleClickBenefit}
              />
            </>
          )}
        </section>
      </div>
    </>
  )
}

const selectMainState = createSelector((state: RootState) => state.settings, (settings) => {
    const {
      euk,
      tabUiModeEnabled,
      mainPopupSuppressed,
      schemeURL,
      isCarLifeTermsAgreed,
    } = settings
    return {
      euk,
      tabUiModeEnabled,
      mainPopupSuppressed,
      schemeURL,
      isCarLifeTermsAgreed,
    }
  },
)

export default Main
