import styled from '@emotion/styled'
import React, { ForwardedRef, forwardRef, HTMLAttributes, PropsWithChildren } from 'react'
import { css } from '@emotion/react'
import { Button } from '../Button'
import IconNew from './../../assets/images/NewBadge.svg'

type CardStyleType = {
  minHeight?: number
  marginTop?: number
  isLine?: boolean
  isOverflow?: boolean
}

interface SectionCardType extends CardStyleType, PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  id?: string
  title?: string
  titleImg?: string | null
  description?: string | null
  isNew?: boolean
  isSmallNew?: boolean
  isMore?: boolean
  handleClickMore?: () => void
}

const SectionCard = forwardRef((props: SectionCardType, ref?: ForwardedRef<HTMLElement>) => {
  const {
    id,
    title,
    titleImg,
    description,
    isNew,
    isSmallNew,
    isMore,
    minHeight,
    marginTop,
    isLine,
    isOverflow = true,
    handleClickMore,
    children,
    ...attr
  } = props

  return (
    <Card
      id={id}
      minHeight={minHeight}
      marginTop={marginTop}
      isOverflow={isOverflow}
      ref={ref}
      {...attr}
    >
      {titleImg && (
        <CardTitleImg
          src={titleImg}
          alt=""
        />
      )}
      {title && (
        <CardTitle
          isNew={isNew}
          isSmallNew={isSmallNew}
          isLine={isLine}
        >
          {title}
          {isNew && <em className="new">New</em>}
          {isSmallNew && <em className="new_small">New</em>}
        </CardTitle>
      )}
      {description && <CardDescription>{description}</CardDescription>}
      {children}
      {isMore && (
        <CardMore>
          <Button
            variant={'simple'}
            styleHeight={32}
            stylePadding={'0 8px'}
            styleFontSize={'normal'}
            onClick={handleClickMore}
          >
            전체보기
          </Button>
        </CardMore>
      )}
    </Card>
  )
})

export { SectionCard }

const Card = styled.section<CardStyleType>`
  ${({ isOverflow }) => isOverflow && css`overflow: hidden`}
  position: relative;
  ${({ minHeight }) => minHeight && css`min-height: ${minHeight}px;`}
  margin: 12px 16px;
  ${({ marginTop }) => marginTop && css`margin-top: ${marginTop}px;`}
  border: 1px solid var(--gray-100);
  border-radius: 12px;
  box-sizing: border-box;
  background: var(--C-white);
`

const CardTitleImg = styled.img`
  width: 100%;
`

const CardTitle = styled.h2<SectionCardType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 20px 20px 8px;
  line-height: 2.8rem;
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-bold);

  ${({ isLine }) => isLine && css`
    margin: 0;
    padding: 20px;
    border-bottom: 1px solid var(--gray-100);
  `}
  ${({ isNew }) => isNew && css`
    .new {
      content: '';
      width: 35px;
      height: 20px;
      margin-left: 8px;
      border-radius: 10px;
      color: var(--C-red-500);
      line-height: 2rem;
      text-align: center;
      font-size: var(--font-size-11);
      font-weight: var(--font-weight-bold);
      background-color: var(--C-red-200);
    }
  `}
  ${({ isSmallNew }) => isSmallNew && css`
    .new_small {
      content: '';
      overflow: hidden;
      width: 16px;
      height: 16px;
      margin-left: 4px;
      background: url(${IconNew}) no-repeat 0 0 / 100% 100%;
      text-indent: -100px;
      font-size: 1px;
    }
  `}
`

const CardDescription = styled.p`
  margin: 0 20px;
  color: var(--C-gray-500);
  line-height: 2rem;
  font-size: var(--font-size-14);
  word-break: keep-all;
`

const CardMore = styled.div`
  position: absolute;
  top: 18px;
  right: 20px;
`
