import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { getCarMaintenance } from '@tmap-web-lib/remote-api-client/frontman'
import {
  CarMaintenanceDataType,
} from '@tmap-web-lib/remote-api-client/dist/types/frontman/apis/driving-score/types/car-maintenance'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'
import { mockHost } from '../../api'

function useGetCarMaintenance(carProfileId: string, useErrorBoundary?: boolean) {
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useQuery<CarMaintenanceDataType, AxiosError>([KEYS.CAR_MAINTENANCE(carProfileId)],
    async () => {
      if (JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.get<Required<CarMaintenanceDataType>>('/driving-score/mycar/users/$userKey/cars/car_profile_id/maintenance')
        return data
      } else {
        const { data } = await getCarMaintenance(carProfileId)
        return data
      }
    },
    {
      enabled: !!carProfileId,
      useErrorBoundary: useErrorBoundary === undefined,
    },
  )
}

export { useGetCarMaintenance }
