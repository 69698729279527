import React, { useCallback, useRef, useState } from 'react'
import {
  DialogBackground,
  DialogCancelButton,
  DialogFrame,
  DialogCustom,
  DialogFooter,
  DialogOkButton,
} from './Elements'
import { DialogBaseProps } from './types'
import { CSSTransition } from 'react-transition-group'
import { ButtonTapHighlight } from '../Button'
import styled from '@emotion/styled'
import iconDel from '../../assets/images/ico_20_delete.svg'
import iconCheckboxOn from '../../assets/images/check_box_small_on.svg'
import iconCheckboxOff from '../../assets/images/check_box_small_off.svg'
import { NumberFormatValues, NumericFormat } from 'react-number-format'
import { INFINITY_MILEAGE } from '../../pages/Driving/vehicle/hooks'

interface Props extends DialogBaseProps {
  title?: string
  value: string
  maxLength?: number
  placeholder?: string
  isInfinity?: boolean
}
function DialogUpdateValue(props: Props) {
  const { title, value, placeholder, isInfinity, okText, cancelText, onOk, onCancel } = props

  const backgroundRef = useRef(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isOpen, setOpen] = useState(true)
  const [inputValue, setInputValue] = useState<string>(Number(value) === INFINITY_MILEAGE ? '' : value)
  const [checked, setChecked] = useState(Number(value) === INFINITY_MILEAGE)
  const [isShowButton, setShowButton] = useState(!!value)

  const onValueChange = useCallback((values: NumberFormatValues) => {
    setShowButton(!!values.value)
    setInputValue(values.value)
    setChecked(!!isInfinity && !values.value)
  }, [isInfinity])

  const onChecked = useCallback((e:  HTMLInputElement) => {
    setInputValue('')
    setChecked(e.checked)
  }, [])

  const onDelete = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
    setShowButton(false)
    setInputValue('')
    setChecked(!!isInfinity && true)
  }, [inputRef, isInfinity])

  const handleModifyClick = useCallback(() => {
    onOk?.(inputValue, checked)
    setOpen(false)
  }, [inputValue, checked, onOk])

  return (
    <CSSTransition
      classNames="dimmed-fade-in"
      unmountOnExit={true}
      timeout={300}
      in={isOpen}
      nodeRef={backgroundRef}
      appear
    >
      <DialogBackground ref={backgroundRef}>
        <DialogFrame
          className={'default'}
          content={
            <DialogCustom
              title={title}
              content={
                <Form>
                  <FormText>
                    <NumericFormat
                      type={'text'}
                      getInputRef={inputRef}
                      value={inputValue}
                      allowNegative={false}
                      inputMode={'numeric'}
                      maxLength={props.maxLength ?? 10}
                      placeholder={placeholder}
                      thousandSeparator=","
                      decimalScale={0}
                      customInput={FormTextInput}
                      onValueChange={onValueChange}
                    />
                    {isShowButton && (
                      <ButtonTapHighlight onClick={onDelete}>
                        입력값 삭제
                      </ButtonTapHighlight>
                    )}
                  </FormText>
                  {isInfinity && (
                    <FormCheck>
                      <FormCheckInput
                        type="checkbox"
                        checked={checked}
                        onChange={(e) => onChecked(e.currentTarget)}
                      />
                      무제한
                    </FormCheck>
                  )}
                </Form>
              }
            />
          }
          buttonSet={
            <DialogFooter
              cancelButton={
                <DialogCancelButton
                  borderRadius={'0'}
                  onClick={onCancel}
                >
                  {cancelText ?? '취소'}
                </DialogCancelButton>
              }
              okButton={
                <DialogOkButton
                  borderRadius={'0'}
                  gap={'0'}
                  onClick={handleModifyClick}
                >
                  {okText ?? '입력'}
                </DialogOkButton>
              }
            />
          }
        />
      </DialogBackground>
    </CSSTransition>
  )
}

export default DialogUpdateValue
export { DialogUpdateValue }

const Form = styled.div`
  margin: 10px 48px 24px;
`
const FormText = styled.div`
  position: relative;

  > button {
    overflow: hidden;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: url(${iconDel}) no-repeat 0 0 / 100% 100%;
    text-indent: -100px;
    font-size: 1px;
  }
`
const FormTextInput = styled.input`
  width: 100%;
  height: 60px;
  padding-right: 36px;
  border: 0;
  border-bottom: 2px solid var(--C-gray-900);
  border-radius: 0;
  box-sizing: border-box;
  outline: none;
  line-height: 3.2rem;
  font-size: 2.4rem;
  font-weight: var(--font-weight-bold);
  
  &::placeholder {
    color: var(--C-gray-400);
    font-weight: var(--font-weight-normal);
  }
`
const FormCheck = styled.label`
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-top: 18px;
  color: var(--C-gray-700);
  line-height: 2.4rem;
  font-size: var(--font-size-18);
`
const FormCheckInput = styled.input`
  margin-right: 10px;
  width: 24px;
  height: 24px;
  background: url(${iconCheckboxOff}) no-repeat 0 0 / 100% 100%;
  
  &:checked {
    background-image: url(${iconCheckboxOn});
  }
`
