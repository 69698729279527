import { createSelector, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'
import { RootState } from '../index'
import { createInitialState } from './create-initial-state'
import {
  STORAGE_KEY_LIFE_DEV_TOOL_ACCESS_KEY,
  STORAGE_KEY_LIFE_SCHEME_URL,
  STORAGE_KEY_LIFE_STORE_ACCESS_KEY,
  STORAGE_KEY_LIFE_STORE_MAIN_POPUP_SUPPRESSED,
  STORAGE_KEY_LIFE_UPDATE_OUTER_CAR_INFO,
  STORAGE_KEY_LIFE_VEHICLE_MAINTENANCE_UPDATE_ITEMS,
  STORAGE_KEY_LIFE_EMERGENCY_CALL_CHECKED,
  STORAGE_KEY_LIFE_DEV_TOOL_MOCK_ENABLED,
  STORAGE_KEY_LIFE_OPTIONAL_MARKETING_TERMS_STATUS,
  STORAGE_KEY_LIFE_OTP_CLOSED,
  STORAGE_KEY_LIFE_VEHICLE_INSURANCE_INFO,
} from './storage-keys'
import { PageIdSearchParams } from '../../providers/page-id-context'
import qs from 'qs'

type SettingsState = ReturnType<typeof createInitialState>
export type TermsState = Boolean | null

export function generatePageUrl(pathname: string, params: PageIdSearchParams = {}) {
  const querystring = qs.stringify(params, { skipNulls: true })
  return `${process.env.REACT_APP_TSCORE_URL}${pathname}${querystring ? `?${querystring}` : ''}`
}

interface Actions extends SliceCaseReducers<SettingsState> {
  setDevToolsAccessKey: (state: SettingsState, action: PayloadAction<string>) => void;
  setAccessKey: (state: SettingsState, action: PayloadAction<string>) => void;
  setDeviceId: (state: SettingsState, action: PayloadAction<string>) => void;
  setCarrierName: (state: SettingsState, action: PayloadAction<string>) => void;
  setEuk: (state: SettingsState, action: PayloadAction<string>) => void;
  setSessionId: (state: SettingsState, action: PayloadAction<string>) => void;
  setCurrentUrl: (state: SettingsState, action: PayloadAction<string>) => void;
  setCookies: (state: SettingsState, action: PayloadAction<string>) => void;
  setMockEnabled: (state: SettingsState, action: PayloadAction<boolean>) => void;
  setMainPopupSuppressed: (state: SettingsState, action: PayloadAction<boolean>) => void;
  setIsCarLifeTermsAgreed: (state: SettingsState, action: PayloadAction<TermsState>) => void;
  setAccessKeyExpired: (state: SettingsState) => void;
  setIsSkeleton: (state: SettingsState, action: PayloadAction<boolean>) => void;
  setSchemeURL: (state: SettingsState, action: PayloadAction<string>) => void;
  setUpdateOuterCarInfo: (state: SettingsState, action: PayloadAction<string>) => void;
  setHeaderHeight: (state: SettingsState, action: PayloadAction<number>) => void;
  setVehicleMaintenanceUpdateItems: (state: SettingsState, action: PayloadAction<string>) => void;
  setEmergencyCallChecked: (state: SettingsState, action: PayloadAction<string>) => void;
  setTermsAfterAction: (state: SettingsState, action: PayloadAction<boolean>) => void;
  setOptionalMarketingTermsStatus: (state: SettingsState, action: PayloadAction<string>) => void;
  setOtpClosed: (state: SettingsState, action: PayloadAction<string>) => void;
  setVehicleInsuranceInfo: (state: SettingsState, action: PayloadAction<string>) => void;
}

const settingsSlice = createSlice<SettingsState, Actions>({
  name: 'settings',
  initialState: createInitialState(),
  reducers: {
    setDevToolsAccessKey: (state, action) => {
      const accessKey = action.payload
      if (accessKey) {
        localStorage.setItem(STORAGE_KEY_LIFE_DEV_TOOL_ACCESS_KEY, accessKey)
      } else {
        localStorage.removeItem(STORAGE_KEY_LIFE_DEV_TOOL_ACCESS_KEY)
      }
    },
    setAccessKey(state: SettingsState, action: PayloadAction<string>) {
      const accessKey = action.payload
      state.accessKey = accessKey
      localStorage.setItem(STORAGE_KEY_LIFE_STORE_ACCESS_KEY, accessKey)
    },
    setCarrierName(state: SettingsState, action: PayloadAction<string>) {
      state.carrierName = action.payload
    },
    setDeviceId(state: SettingsState, action: PayloadAction<string>) {
      state.deviceId = action.payload
    },
    setEuk(state: SettingsState, action: PayloadAction<string>) {
      state.euk = action.payload
    },
    setSessionId(state: SettingsState, action: PayloadAction<string>) {
      state.sessionID = action.payload
    },
    setCurrentUrl(state: SettingsState, action: PayloadAction<string>) {
      state.currentUrl = action.payload
    },
    setCookies(state: SettingsState, action: PayloadAction<string>) {
      state.cookies = action.payload
    },
    setMockEnabled(state: SettingsState, action: PayloadAction<boolean>) {
      const mockEnabeld = action.payload
      state.mockEnabled = mockEnabeld
      localStorage.setItem(STORAGE_KEY_LIFE_DEV_TOOL_MOCK_ENABLED, mockEnabeld.toString())
    },
    setMainPopupSuppressed(state: SettingsState, action: PayloadAction<boolean>) {
      const mainPopupSuppressed = action.payload
      state.mainPopupSuppressed = mainPopupSuppressed
      if (mainPopupSuppressed) {
        const expirationTime = DateTime.now().startOf('day').plus({ days: 7 }).toUTC()
        localStorage.setItem(STORAGE_KEY_LIFE_STORE_MAIN_POPUP_SUPPRESSED, expirationTime.toISO())
      } else  {
        localStorage.removeItem(STORAGE_KEY_LIFE_STORE_MAIN_POPUP_SUPPRESSED)
      }
    },
    setIsCarLifeTermsAgreed(state: SettingsState, action: PayloadAction<TermsState>) {
      state.isCarLifeTermsAgreed = action.payload
    },
    setAccessKeyExpired(state: SettingsState) {
      state.accessKeyExpired = true
    },
    setIsSkeleton(state, action) {
      state.isSkeleton = action.payload
    },
    setSchemeURL(state,  action) {
      const schemeExtraValue = action.payload
      state.schemeURL = schemeExtraValue
      localStorage.setItem(STORAGE_KEY_LIFE_SCHEME_URL, schemeExtraValue)
    },
    setUpdateOuterCarInfo(state, action) {
      const caInfo = action.payload
      state.updateOuterCarInfo = caInfo
      localStorage.setItem(STORAGE_KEY_LIFE_UPDATE_OUTER_CAR_INFO, caInfo)
    },
    setHeaderHeight(state, action) {
      state.headerHeight = action.payload
    },
    setVehicleMaintenanceUpdateItems(state, action) {
      const vehicleMaintenanceUpdateItems = action.payload
      state.vehicleMaintenanceUpdateItems = vehicleMaintenanceUpdateItems
      localStorage.setItem(STORAGE_KEY_LIFE_VEHICLE_MAINTENANCE_UPDATE_ITEMS, vehicleMaintenanceUpdateItems)
    },
    setEmergencyCallChecked(state, action) {
      const emergencyCallChecked = action.payload
      state.emergencyCallChecked = emergencyCallChecked
      localStorage.setItem(STORAGE_KEY_LIFE_EMERGENCY_CALL_CHECKED, `${emergencyCallChecked}`)
    },
    setTermsAfterAction(state, action) {
      state.termsAfterAction = action.payload
    },
    setOptionalMarketingTermsStatus(state, action) {
      const payload = action.payload
      state.optionMarketingTermsStatus = payload
      localStorage.setItem(STORAGE_KEY_LIFE_OPTIONAL_MARKETING_TERMS_STATUS, payload)
    },
    setOtpClosed(state, action) {
      const payload = action.payload
      state.otpClosed = payload
      localStorage.setItem(STORAGE_KEY_LIFE_OTP_CLOSED, payload)
    },
    setVehicleInsuranceInfo(state, action) {
      const payload = action.payload
      state.vehicleInsuranceInfo = payload
      localStorage.setItem(STORAGE_KEY_LIFE_VEHICLE_INSURANCE_INFO, payload)
    },
  },
})

export { settingsSlice }

export const {
  setDevToolsAccessKey,
  setDeviceId,
  setCarrierName,
  setCookies,
  setAccessKey,
  setEuk,
  setSessionId,
  setCurrentUrl,
  setMockEnabled,
  setMainPopupSuppressed,
  setIsCarLifeTermsAgreed,
  setAccessKeyExpired,
  setIsSkeleton,
  setSchemeURL,
  setUpdateOuterCarInfo,
  setHeaderHeight,
  setVehicleMaintenanceUpdateItems,
  setEmergencyCallChecked,
  setOptionalMarketingTermsStatus,
  setOtpClosed,
  setVehicleInsuranceInfo,
} = settingsSlice.actions

export const selectSettingsCookies = (state: RootState) => state.settings.cookies
export const selectSettingsCurrentUrl = (state: RootState) => state.settings.currentUrl
export const selectSettingsAccessKey = (state: RootState) => state.settings.accessKey
export const selectSettingsEuk = (state: RootState) => state.settings.euk
export const selectSettingsMockEnabled = (state: RootState) => state.settings.mockEnabled
export const selectSettingsMainPopupSuppressed = (state: RootState) => state.settings.mainPopupSuppressed
export const selectSettingsIsCarLifeTermsAgreed = (state: RootState) => state.settings.isCarLifeTermsAgreed
export const selectSettingsIsSkeleton = (state: RootState) => state.settings.isSkeleton
export const selectSettingsSchemeURL = (state: RootState) => state.settings.schemeURL
export const selectSettingsUpdateOuterCarInfo = (state: RootState) => state.settings.updateOuterCarInfo
export const selectHeaderHeight = (state: RootState) => state.settings.headerHeight
export const selectVehicleMaintenanceUpdateItems = (state: RootState) => state.settings.vehicleMaintenanceUpdateItems
export const selectSettingsEmergencyCallChecked = (state: RootState) => state.settings.emergencyCallChecked
export const selectCustomUrlTscorePageUrlGenerator = createSelector([
  selectSettingsAccessKey,
], (ak) => {
  return (pathname: string, params: PageIdSearchParams = {}) => {
    return generatePageUrl(pathname, { ...params, ak })
  }
})
export const selectOptionalMarketingTermsStatus = (state: RootState) => state.settings.optionMarketingTermsStatus
export const selectOtpClosed = (state: RootState) => state.settings.otpClosed
export const selectVehicleInsuranceInfo = (state: RootState) => state.settings.vehicleInsuranceInfo
