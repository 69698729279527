import { HTMLAttributes, PropsWithChildren } from 'react'

import s from '../../styles/modules/component/Card.module.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  title?: string
  description?: string
}

function Card(props: Props) {
  const { title, description, children } = props
  return (
    <section className={s.wrap}>
      {title && <h1 className={s.title}>{title}</h1>}
      {description && <p className={s.description}>{description}</p>}

      {children}
    </section>
  )
}

export { Card }
