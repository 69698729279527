import { useQuery } from '@tanstack/react-query'
import { getLatestViolations, LatestViolationData } from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'
import { mockHost } from '../../api'

interface Options {
  enabled?: boolean
  useErrorBoundary?: boolean
}

function useGetLatestViolations(options?: Options) {
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useQuery<LatestViolationData, AxiosError>([KEYS.LATEST_VIOLATIONS()],
    async () => {
      if (JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.get<LatestViolationData>('/ubds/trip-report/v1/latest-violations')
        return data
      } else {
        const { data } = await getLatestViolations()
        return data
      }
    },
    {
      enabled: options?.enabled ?? true,
      useErrorBoundary: !!options?.useErrorBoundary,
    },
  )
}

export { useGetLatestViolations }
