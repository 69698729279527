import { useQuery } from '@tanstack/react-query'
import { getUserReddotList, RedddotListData } from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'

interface Props {
  itemGroup: 'DRIVING_LIFE' | 'DRIVING_LIFE_SERVICE'
  enabled?: boolean
  useErrorBoundary?: boolean
}
function useGetUserRedDotList(props: Props) {
  const { itemGroup = 'DRIVING_LIFE', enabled, useErrorBoundary } = props

  return useQuery<RedddotListData, AxiosError>([KEYS.RED_DOT_LIST(itemGroup)],
    () => getUserReddotList(itemGroup).then(({ data }) => data),
    {
      enabled: enabled ?? true,
      useErrorBoundary: useErrorBoundary ?? true,
    }
  )
}

export { useGetUserRedDotList }
