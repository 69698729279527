export enum RoutePath {
  Home = '/web',
  OldHome = '/web/main',
  Benefit = '/web/benefit',
  Benefit_popular = '/web/benefit/popular',
  Benefit_detail = '/web/benefit/detail',
  Insurance = '/web/insurance', // 앱 전체 > 자동차보험 메뉴에서 들어오는 url
  Driving_analysis = '/web/analysis',
  Vehicle = '/web/vehicle',
  Vehicle_setting = '/web/vehicle/setting',
  Vehicle_detail = '/web/vehicle/detail',
  Unpaid_toll = '/web/unpaid_toll',
  unpaid_toll_bridge = '/web/unpaid_toll/bridge',
  unpaid_toll_result = '/web/unpaid_toll/result',
  History = '/web/history',
  History_detail = '/web/history/detail',
  Etc_insurer_contact = '/web/etc/contact',
  Etc_emergency_call = '/web/etc/emergency',
  Etc_terms = '/web/etc/terms',
  Settings = '/web/settings',
  Settings_otp = '/web/settings/otp',
  Settings_withdrawal = '/web/settings/withdrawal'
}

export const BenefitPageList = [
  RoutePath.Benefit,
  RoutePath.Benefit_popular,
  RoutePath.Benefit_detail,
  RoutePath.Insurance
]

export const DrivingPageList = [
  RoutePath.Driving_analysis,
  RoutePath.Vehicle,
]

export const VehicleSubPageList = [
  RoutePath.Vehicle_setting,
  RoutePath.Vehicle_detail,
  RoutePath.Unpaid_toll,
  RoutePath.unpaid_toll_bridge,
  RoutePath.unpaid_toll_result,
]

export const HistoryPageList = [
  RoutePath.History,
  RoutePath.History_detail,
]

export const EtcPageList = [
  RoutePath.Etc_insurer_contact,
  RoutePath.Etc_emergency_call,
  RoutePath.Etc_terms,
]

export const SettingsPageList = [
  RoutePath.Settings,
  RoutePath.Settings_otp,
  RoutePath.Settings_withdrawal,
]
