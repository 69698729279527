import { fetchAndActivate, getValue } from 'firebase/remote-config'
import { remoteConfig } from '../../config'
import { useCallback, useEffect, useState } from 'react'
import mixpanel from 'mixpanel-browser'
import { datadogRum } from '@datadog/browser-rum'
import { Value } from '@firebase/remote-config'
import debounce from 'lodash-es/debounce'

export const CONTROL_GROUP = 'B' // 대조군

interface Options {
  key: string
}

function useFirebaseRemoteConfig(options: Options) {
  const { key } = options

  const [isLoading, setLoading] = useState(true)
  const [remoteConfigValue, setRemoteConfigValue] = useState<Value>()

  const fetch = useCallback(async () => debounce(async () => {
    await fetchAndActivate(remoteConfig)
  }, 0), [])

  useEffect(() => {
    fetch()
      .then(() => {
        setRemoteConfigValue(getValue(remoteConfig, key))
      })
      .catch((error) => {
        mixpanel.track(error.response?.data ? `tscore:error:remoteConfig:${error.response.data}` : 'tscore:error:remoteConfig', { error: error })
        datadogRum.addError(error, {
          error: {
            ...error,
            request: error.request?.response,
            message: error.response?.data ? `tscore:error:remoteConfig:${error.response?.data}` : 'tscore:error:remoteConfig',
          },
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [key, fetch])

  return {
    isLoading,
    remoteConfigValue,
  }
}

export { useFirebaseRemoteConfig }
