import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { DateTime } from 'luxon'
import { ObjTooltip } from '../static/tooltip'
import { CarMaintenanceStandardType, CarProfile } from '@tmap-web-lib/remote-api-client/frontman'
import {
  DeepPartial,
  EngineOilType,
  TireType,
  WarrantyType,
} from '@tmap-web-lib/remote-api-client/dist/types/frontman/apis/driving-score/types/car-maintenance'
import { useQueryClient } from '@tanstack/react-query'
import {
  KEYS,
  useGetCarMaintenance,
  useRegisterCarMaintenance,
  useUpdateUserMessageReset
} from '../../../../react-query'

export type CarMaintenanceDetailType = 'TIRE' | 'WARRANTY' | 'ENGINE_OIL' | 'RECALL' | 'AIRCON_FILTER' | 'AIR_FILTER'
export type CarMaintenanceDetailState = 'NORMAL' | 'WARNING' | 'NONE'
export type CarMaintenanceDetailInfo = {
  date: string | null
  mileage: number | null
  isUpdateDate: boolean
  isUpdateMileage: boolean
  dateTitle: string
  mileageTitle: string
  popupDateTitle: string
  popupMileageTitle: string
  defaultTooltip: ReactNode
  detailTooltips: ObjTooltip
}
export interface CarMaintenanceDetailGauge {
  current: string
  status?: string
  remain: string
  total: string
  percentage: number
  isExceed: boolean
  isInfinity?: boolean
  isNotModified?: boolean
  handleUpdate?: () => void
}
export type CarMaintenanceDetail = {
  type: CarMaintenanceDetailType
  title: ReactNode
  state: CarMaintenanceDetailState
  info: CarMaintenanceDetailInfo
}

export interface CarTire extends TireType {
  isUpdateDate: boolean
  isUpdateMileage: boolean
}
export interface CarWarranty extends WarrantyType {
  isUpdateDate: boolean
  isUpdateMileage: boolean
}
export interface CarEngineOil extends EngineOilType {
  isUpdateDate: boolean
  isUpdateMileage: boolean
}
export interface CarAirconditionerFilter extends CarMaintenanceStandardType {
  isUpdateDate: boolean
  isUpdateMileage: boolean
}
export interface CarAirFilter extends CarMaintenanceStandardType {
  isUpdateDate: boolean
  isUpdateMileage: boolean
}

export interface CarMaintenance {
  userMileage: number | null
  tire: CarTire
  warranty: CarWarranty
  engineOil: CarEngineOil
  airconditionerFilter: CarAirconditionerFilter
  airFilter: CarAirFilter
}

export const TIRE_EXPIRED_MONTHS = 60 // 타이어 기준 개월수(단위 월)
export const TIRE_EXPIRED_MILEAGE = 50000 // 타이어 기준 거리(단위 km)
export const ENGINE_OIL_EXPIRED_MONTHS = 12 // 엔진오일 기준 개월수(단위 월)
export const ENGINE_OIL_EXPIRED_MILEAGE = 10000 // 엔진오일 기준 거리(단위 km)
export const AIRCON_FILTER_EXPIRED_MONTHS = 12 // 에어컨필터 기준 개월수(단위 월)
export const AIRCON_FILTER_EXPIRED_MILEAGE = 15000 // 에어컨필터 기준 거리(단위 km)
export const AIR_FILTER_EXPIRED_MONTHS = 24 // 엔진에어필터 기준 개월수(단위 월)
export const AIR_FILTER_EXPIRED_MILEAGE = 40000 // 엔진에어필터 기준 거리(단위 km)
export const INFINITY_MILEAGE = 2147483647 // 무제한 주행거리 값(자바 int 최대값을 적용)

function useMergeCarProfileIntoCarMaintenance(carProfile: CarProfile | null, useErrorBoundary?: boolean) {
  const { data: maintenance, status: maintenanceStatus, refetch: refetchMaintenance } = useGetCarMaintenance(carProfile?.carProfileId || '', useErrorBoundary)
  const { mutate, isLoading, status: registerCarMaintenanceStatus } = useRegisterCarMaintenance(carProfile?.carProfileId || '')
  const { mutate: resetUserMessage } = useUpdateUserMessageReset({ useErrorBoundary: false })
  const queryClient = useQueryClient()

  const [data, setData] = useState<CarMaintenance>()

  // 내차관리 API userMileage 먼저 체크하고 차량프로필의 운행거리 순으로 체크함.
  const mileage = useMemo(() => {
    if (maintenance && maintenance.userMileage) {
      return maintenance.userMileage
    }
    return Number(carProfile?.carWonbu?.userMileage || carProfile?.carWonbu?.mileage) || 0
  }, [maintenance, carProfile])

  const getPredictedMileage = useCallback((date: string | null) => {
    // 검사소 기록 및 mileage 가 있는 유저에게 예상 타이어 주행거리 노출(기록 없는 경우 '입력하기' 표기)
    // 평균 마일리지 = mileage(원부데이터의 수정한 마일리지(userMileage) 또는 검사 시 주행거리(mileage)) / (검사기간(월) - 출고월)
    // 타이어, 엔진오일 주행거리(추정거리) = 평균 마일리지 * (조회월 - 최근교체일)
    if (!carProfile || !date) return null
    const { carWonbu } = carProfile
    let startMileage: number | null = null

    if (carWonbu?.firstRegDt && carWonbu?.startInsptExp && mileage) {
      const start = DateTime.fromISO(carWonbu.firstRegDt).toFormat('yyyyMM')
      const end = DateTime.fromISO(carWonbu.startInsptExp).toFormat('yyyyMM')
      const now = DateTime.now().toFormat('yyyyMM')
      const recentDate = DateTime.fromISO(date).toFormat('yyyyMM')
      const monthsOfTireUse = DateTime.fromISO(now).diff(DateTime.fromISO(recentDate), 'months')
      let averageMonthlyMiles: number

      // 출고일과 검사일이 같은 경우 현재월 - 출고월로 계산
      if (carWonbu.firstRegDt >= carWonbu.startInsptExp) {
        const diff = DateTime.fromISO(now).diff(DateTime.fromISO(start), 'months')
        averageMonthlyMiles = mileage / diff.months
      } else {
        const diff = DateTime.fromISO(end).diff(DateTime.fromISO(start), 'months')
        averageMonthlyMiles = mileage / diff.months
      }

      startMileage = Math.round(averageMonthlyMiles * monthsOfTireUse.months)
    }
    return startMileage
  }, [carProfile, mileage])

  const init = useCallback(() => {
    if (!carProfile || !maintenance) return
    const { carWonbu, extCarInfo } = carProfile
    const { userMileage, tire, warranty, engineOil, airconditionerFilter, airFilter } = maintenance
    const { warrantyStartDate, basicWarrantyPeriod, basicWarrantyMileage, powertrainWarrantyPeriod, powertrainWarrantyMileage } = warranty
    let enginOilMileage: number | null = null
    let airconditionerFilterMileage: number | null = null
    let airFilterMileage: number | null = null


    if (engineOil.mileageAfterReplacement !== null) {
      enginOilMileage = engineOil.mileageAfterReplacement
    } else if (engineOil.replacementDate) {
      enginOilMileage = getPredictedMileage(engineOil.replacementDate || (carWonbu?.firstRegDt || null))
    }
    if (airconditionerFilter.mileageAfterReplacement !== null) {
      airconditionerFilterMileage = airconditionerFilter.mileageAfterReplacement
    } else if (airconditionerFilter.replacementDate) {
      airconditionerFilterMileage = getPredictedMileage(airconditionerFilter.replacementDate || (carWonbu?.firstRegDt || null))
    }
    if (airFilter.mileageAfterReplacement !== null) {
      airFilterMileage = airFilter.mileageAfterReplacement
    } else if (airFilter.replacementDate) {
      airFilterMileage = getPredictedMileage(airFilter.replacementDate || (carWonbu?.firstRegDt || null))
    }

    const getData = (value?: string) => {
      if (!value) return null
      // 보증관련 원부정보에 "2/3/4", "OOOO마일" 값이 있는 경우 null로 예외 처리
      // 보증주행거리(원부정보)가 "무제한"일 경우 최대값 적용(INFINITY_MILEAGE)
      const isException = value.includes('/') || value.includes('마일')
      const isInfinity = value === '무제한'
      if (isException) {
        return null
      } else if (isInfinity) {
        return INFINITY_MILEAGE
      } else {
        return Number(value) ? Number(value) : null
      }
    }

    const data: CarMaintenance = {
      userMileage: userMileage || mileage || null,
      tire: {
        replacementDate: tire.replacementDate || carWonbu?.firstRegDt || null,
        mileageAfterReplacement: tire.mileageAfterReplacement !== null ? tire.mileageAfterReplacement : getPredictedMileage(tire.replacementDate || (carWonbu?.firstRegDt || null)),
        isUpdateDate: tire.replacementDate !== null,
        isUpdateMileage: tire.mileageAfterReplacement !== null,
      },
      warranty: {
        warrantyStartDate: warrantyStartDate || carWonbu?.firstRegDt || null,
        basicWarrantyPeriod: basicWarrantyPeriod || getData(extCarInfo?.g1Year),
        basicWarrantyMileage: basicWarrantyMileage || getData(extCarInfo?.g1Mileage),
        powertrainWarrantyPeriod: powertrainWarrantyPeriod || getData(extCarInfo?.g2Year),
        powertrainWarrantyMileage: powertrainWarrantyMileage || getData(extCarInfo?.g2Mileage),
        isUpdateDate: warrantyStartDate !== null,
        isUpdateMileage: userMileage !== null,
      },
      engineOil: {
        replacementDate: engineOil.replacementDate || null,
        mileageAfterReplacement: enginOilMileage, // 최근 교체일이 입력되어야 ‘교체후 주행’를 계산하여 표기(주행거리 로직은 타이어와 같음)
        isUpdateDate: engineOil.replacementDate !== null,
        isUpdateMileage: engineOil.mileageAfterReplacement !== null,
      },
      airconditionerFilter: {
        replacementDate: airconditionerFilter.replacementDate || null,
        mileageAfterReplacement: airconditionerFilterMileage, // 최근 교체일이 입력되어야 ‘교체후 주행’를 계산하여 표기(주행거리 로직은 타이어와 같음)
        isUpdateDate: airconditionerFilter.replacementDate !== null,
        isUpdateMileage: airconditionerFilter.mileageAfterReplacement !== null,
      },
      airFilter: {
        replacementDate: airFilter.replacementDate || null,
        mileageAfterReplacement: airFilterMileage, // 최근 교체일이 입력되어야 ‘교체후 주행’를 계산하여 표기(주행거리 로직은 타이어와 같음)
        isUpdateDate: airFilter.replacementDate !== null,
        isUpdateMileage: airFilter.mileageAfterReplacement !== null,
      }
    }
    setData(data)
  }, [maintenance, carProfile, mileage, getPredictedMileage])

  // setState 에 변경된 정보를 반영 하여 정보 수정 시 바로 변경 되도록 적용.
  // '예상' 표기 유무는 api 업데이트 후 처리하도록 예외 처리함.
  const updateData = useCallback((params: DeepPartial<CarMaintenance>, type: CarMaintenanceDetailType) => {
    let userMileage: number | null = null
    Object.keys(params).forEach((k) => {
      if (k === 'userMileage') {
        userMileage = params[k] || null
      }
    })

    // 내차관리 업데이트
    mutate(params, {
      onSuccess() {
        queryClient.invalidateQueries([KEYS.CAR_MAINTENANCE(carProfile?.carProfileId)])
        // typeCode에 해당하는 소재들 모두 초기화
        switch (type) {
          case 'TIRE':
            return resetUserMessage({ typeCode: 'TIRE_CHECK_GUIDE' })
          case 'WARRANTY':
            return resetUserMessage({ typeCode: 'WARRANTY_PERIOD_EXPIRATION_GUIDE' })
          case 'ENGINE_OIL':
            return resetUserMessage({ typeCode: 'ENGINE_OIL_CHECK_GUIDE' })
          default:
            return
        }
      },
    })
    setData((prev) => {
      if (!prev) return
      switch (type) {
        case 'TIRE':
          // 운전점수에 등록된 주행거리가 없다면 최근교체일이 등록되면 예상 주행거리를 계산해서 입력
          // 운전점수에 등록된 주행거리가 있다면 최근교체일을 변경하여도 주행거리를 다시 계산하지 않음
          // 운전점수에 API에 업데이트 후 재조회를 하기 때문에 'mileageAfterReplacement' 값이 null로 체크를 함
          if (maintenance && maintenance.tire.mileageAfterReplacement === null && params.tire?.replacementDate) {
            const tireMileage = getPredictedMileage(params.tire.replacementDate)
            return { ...prev, tire: { ...prev.tire, ...params.tire, mileageAfterReplacement: tireMileage }}
          } else {
            return { ...prev, tire: { ...prev.tire, ...params.tire }}
          }
        case 'WARRANTY':
          if (userMileage) {
            return { ...prev, userMileage, warranty: { ...prev.warranty, ...params.warranty }}
          } else {
            return { ...prev, warranty: { ...prev.warranty, ...params.warranty }}
          }
        case 'ENGINE_OIL':
          // 타이어와 동일
          if (maintenance && maintenance.engineOil.mileageAfterReplacement === null && params.engineOil?.replacementDate) {
            const enginOliMileage = getPredictedMileage(params.engineOil.replacementDate)
            return { ...prev, engineOil: { ...prev.engineOil, ...params.engineOil, mileageAfterReplacement: enginOliMileage }}
          } else {
            return { ...prev, engineOil: { ...prev.engineOil, ...params.engineOil }}
          }
        case 'AIRCON_FILTER':
          // 타이어와 동일
          if (maintenance && maintenance.airconditionerFilter.mileageAfterReplacement === null && params.airconditionerFilter?.replacementDate) {
            const airconditionerFilterMileage = getPredictedMileage(params.airconditionerFilter.replacementDate)
            return { ...prev, airconditionerFilter: { ...prev.airconditionerFilter, ...params.airconditionerFilter, mileageAfterReplacement: airconditionerFilterMileage }}
          } else {
            return { ...prev, airconditionerFilter: { ...prev.airconditionerFilter, ...params.airconditionerFilter }}
          }
        case 'AIR_FILTER':
          // 타이어와 동일
          if (maintenance && maintenance.airFilter.mileageAfterReplacement === null && params.airFilter?.replacementDate) {
            const airFilterMileage = getPredictedMileage(params.airFilter.replacementDate)
            return { ...prev, airFilter: { ...prev.airFilter, ...params.airFilter, mileageAfterReplacement: airFilterMileage }}
          } else {
            return { ...prev, airFilter: { ...prev.airFilter, ...params.airFilter }}
          }
        default:
          return prev
      }
    })
  }, [carProfile, maintenance, getPredictedMileage, queryClient, mutate, resetUserMessage])

  // 운전점수 내차정보가 없는 경우 차량프로필 정보를 업데이트
  useEffect(() => {
    if (maintenance) {
      init()
    }
  }, [maintenance, init])

  return { data, updateData, isLoading, maintenanceStatus, registerCarMaintenanceStatus, refetchMaintenance }
}

export { useMergeCarProfileIntoCarMaintenance }
